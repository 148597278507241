<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-dark text-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside order-2 order-lg-1 d-md-flex flex-row-auto position-relative overflow-hidden">
        <!--begin: Aside Container-->
        <div class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13">
          <!--begin::Logo-->
          <!-- <div class="text-center pt-2">
            <img src="assets/media/logos/logo.png" class="max-h-75px" alt="" />
          </div> -->
          <!--end::Logo-->
          <!--begin::Aside body-->
          <div class="d-flex flex-column-fluid flex-column flex-center">
            <!--begin::Signin-->
            <div class="login-form login-signin py-3 py-lg-11">
              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_login_signin_form" v-if="signup === 1">
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2 class="font-weight-bolder font-size-h2 font-size-h1-lg">เข้าสู่ระบบ</h2>
                  <span class="text-muted font-weight-bold font-size-h4">หรือ
                  <router-link
                    to="/register"
                    id="kt_login_signup"
                    class="text-white font-weight-bolder"
                    @click.native="showForm('signup')"
                    >ลงทะเบียนใหม่</router-link>
                  </span>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group">
                  <label class="text-muted font-size-h6 font-weight-bolder">Email</label>
                  <input
                    class="form-control form-control-solid h-auto py-4 px-3 py-lg-7 px-lg-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label class="text-muted font-size-h6 font-weight-bolder pt-5">Password</label>
                    <router-link
                      to="/forgot"
                      class="text-light-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      id="kt_login_forgot"
                      @click.native="showForm('forgot')"
                      >ลืมรหัสผ่าน ?</router-link
                    >
                  </div>
                  <input
                    class="form-control form-control-solid h-auto py-4 px-3 py-lg-7 px-lg-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                  <div class="text-danger mt-1">{{ password_invalid }}</div>
                </div>
                <!--end::Form group-->
                <!--begin::Action-->
                <div class="text-center pt-2">
                  <button ref="kt_login_signin_submit" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" v-bind:class="btn.signin_submit" @click="callSignin">Sign In</button>
                </div>
                <!--end::Action-->
              </form>
              <div class="form-group text-center" v-if="signup === 4">
                <h2 class="font-weight-bolder font-size-h2 font-size-h1-lg">Login with Social Account</h2>
                <div class="h5 text-success">กำล้งเข้าสู่ระบบ กรุณารอสักครู่</div>
              </div>
              <div class="form-group text-center" v-if="signup === 9">
                <div class="h5 text-danger">{{ signup_errortxt }}</div>
                <div class="">
                  <b-btn variant="primary" @click="retryRegister">ลองอีกครั้ง</b-btn>
                </div>
              </div>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
            <!--begin::Signup-->
            <div class="login-form login-signup pt-11">
              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_login_signup_form">
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2 class="font-weight-bolder font-size-h2 font-size-h1-lg">ลงทะเบียนผู้ใช้ใหม่</h2>
                  <p class="text-muted font-weight-bold font-size-h4">กรอกอีเมล์เพื่อเริ่มต้นสร้าง Account</p>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group" v-if="signup === 1">
                  <label class="text-muted font-size-h6 font-weight-bolder">Email</label>
                  <input v-model="form.email" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="email" placeholder="Email" name="email" autocomplete="off" />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <!-- <div class="form-group">
                  <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />I Agree the
                  <a href="#">terms and conditions</a>.
                  <span></span></label>
                </div> -->
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3" v-if="signup === 1">
                  <button type="button" id="kt_login_signup_submit" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4" v-bind:class="btn.signup_submit" v-on:click="callSignup">Sign Up</button>
                  <button type="button" id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4" v-on:click="cancelSignup">Cancel</button>
                </div>
                <!--end::Form group-->
                <div class="form-group" v-if="signup === 2">
                  <div class="h5 text-center text-success">ตรวจสอบเมล์ใน Inbox ของคุณด้วย Browser อันเดียวกันนี้ เพื่อทำการยืนยันการลงทะเบียน</div>
                </div>
                <div class="form-group" v-if="signup === 3">
                  <div class="h5 text-center text-success">กำลังลงทะเบียนด้วย Social Network Account</div>
                </div>
                <div class="form-group" v-if="signup === 9">
                  <div class="h5 text-center text-danger">{{ signup_errortxt }}</div>
                  <div class="text-center">
                    <b-btn variant="primary" @click="retryRegister">ลองอีกครั้ง</b-btn>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Signup-->
            <!--begin::Forgot-->
            <div class="login-form login-forgot pt-11">
              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
                <!--begin::Title-->
                <div class="text-center pb-8">
                  <h2 class="font-weight-bolder font-size-h2 font-size-h1-lg">ลืมรหัสผ่าน</h2>
                  <p class="text-muted font-weight-bold font-size-h4">กรอกอีเมล์ที่ใช้สมัครเพื่อเริ่มการเปลี่ยนรหัสผ่าน</p>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="email" placeholder="Email" name="email" autocomplete="off" />
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3">
                  <button type="button" id="kt_login_forgot_submit" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4">รับอีเมล์เปลี่ยนรหัสผ่าน</button>
                  <button type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4" @click="cancelForgot">Cancel</button>
                </div>
                <!--end::Form group-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Forgot-->
          </div>
          <!--end::Aside body-->
          <!--begin: Aside footer for desktop-->
          <div v-if="state==='signin'&&signup===1">
            <div class="text-center">
              <button type="button" v-on:click="loginGoogle" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-h6">
              <span class="svg-icon svg-icon-md">
                <inline-svg
                  src="media/svg/social-icons/google.svg"
                /> </span>Sign in with Google</button>
            </div>
            <div class="text-center">
              <button type="button" v-on:click="loginFacebook" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-h6">
              <span class="svg-icon svg-icon-md">
                </span>Sign in with Facebook</button>
            </div>
          </div>
          <div v-if="state==='signup'&&signup===1">
            <div class="text-center">
              <button type="button" v-on:click="registerGoogle" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-h6">
              <span class="svg-icon svg-icon-md">
                <inline-svg
                  src="media/svg/social-icons/google.svg"
                /> </span>Register with Google</button>
            </div>
            <div class="text-center">
              <button type="button" v-on:click="registerFacebook" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-h6">
              <span class="svg-icon svg-icon-md">
                </span>Register with Facebook</button>
            </div>
          </div>
          <!--end: Aside footer for desktop-->
        </div>
        <!--end: Aside Container-->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0" style="background-color: #2a3040;">
        <!--begin::Title-->
        <div class="d-flex flex-column justify-content-center text-center pt-lg-30 pb-lg-10 pt-md-5 pt-sm-5 px-lg-5 pt-5 px-7">
          <!--begin::Logo-->
          <div class="text-center pt-2">
            <img src="/images/logo2.png" style="height: 80px" />
          </div>
          <!--end::Logo-->
          <router-link to="/" class="font-size-h3 display4 font-weight-bolder my-7 text-white">Tryfree Esports</router-link>
          <p class="font-weight-bolder font-size-h2-md font-size-lg text-white opacity-70">ร่วมทายผลฟรี ลุ้นรับรางวัลสุดยิ่งใหญ่ กับทายฟรี อีสปอร์ต</p>
        </div>
        <!--end::Title-->
        <!--begin::Image-->
        <div class="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-image: url('images/banner2.png');"></div>
        <!--end::Image-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import JwtService from "@/core/services/jwt.service";

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: ""
      },
      btn: {
        signin_submit: [],
        signup_submit: []
      },
      signup: 1,
      signup_errortxt: '',
      password_invalid: ''
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    var statename = this.$route.name
    var auth = this.$route.query.auth

    switch (statename) {
      case 'register':
        this.state = 'signup'

        if (auth != null) {
          this.signup = 3
        }
        break
      case 'forgot':
        this.state = 'forgot'
        break
      default:
        console.log('default', auth)
        if (auth != null) {
          this.signup = 4
        }
        break
    }

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "ระบุ E-Mail ของบัญชีผู้ใช้"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "ระบุรหัสผ่าน"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "ระบุอีเมล์ที่ต้องการใช้สมัคร"
            },
            emailAddress: {
              message: "ที่อยู่อีเมล์ไม่ถูกต้อง"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "ระบุรหัสผ่านที่ต้องการ"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "ยืนยันรหัสผ่าน"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "การยืนยันรหัสผ่านต้องตรงกัน"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "คุณไม่ได้ยอมรับเงื่อนไข ข้อตกลง"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "ระบุอีเมล์สำหรับเปลี่ยนรหัสผ่าน"
            },
            emailAddress: {
              message: "ที่อยู่อีเมล์ไม่ถูกต้อง"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      // var email = this.form.email;
      // var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // console.log('valid login')

      // set spinner to submit button
      // this.callSignin()
      // dummy delay
      // setTimeout(() => {
      //   // send login request
      //   this.$store
      //     .dispatch(LOGIN, { email, password })
      //     // go to which page after successfully login
      //     .then(() => this.$router.push({ name: "dashboard" }))
      //     .catch(() => {});

      //   submitButton.classList.remove(
      //     "spinner",
      //     "spinner-light",
      //     "spinner-right"
      //   );
      // }, 200);
    });

    // this.fv.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Please, provide correct data!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false
    //   });
    // });

    this.fv1.on("core.form.valid", () => {
      // const email = this.$refs.remail.value;
      // const password = this.$refs.rpassword.value;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signup_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
      //   // send register request
      //   this.$store
      //     .dispatch(REGISTER, {
      //       email: email,
      //       password: password
      //     })
      //     .then(() => this.$router.push({ name: "dashboard" }));

      //   submitButton.classList.remove(
      //     "spinner",
      //     "spinner-light",
      //     "spinner-right"
      //   );
      // }, 2000);
    });

    // this.fv1.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Please, provide correct data!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false
    //   });
    // });
  
    firebase.auth().getRedirectResult()
      .then(this.registerCallback)
      .catch(this.registerError)
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );

      this.password_invalid = ''
    },
    callSignin() {
      this.btn.signin_submit = ["spinner", "spinner-light", "spinner-right"]
      this.password_invalid = ''

      this.$store
        .dispatch(LOGIN, this.form)
        // go to which page after successfully login
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((data) => {
          console.error('error', data.data.failed)
          switch (data.data.failed) {
            case 'user':
            case 'password_invalid':
              this.password_invalid = 'ชื่อบัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'
              break
          }
        })

      this.btn.signin_submit = [];

      // this.axios.post('/auth/login', this.form).then(result => {
      //   JwtService.saveToken(result.data.token)

      //   this.$router.push({ name: 'dashboard' })
      //   console.log('auth login')
      // })
    },
    callSignup() {
      this.btn.signup_submit = ["spinner", "spinner-light", "spinner-right"]

      var actionCodeSettings = {
        url: process.env.VUE_APP_CONFIRM_URL,
        handleCodeInApp: true,
        // dynamicLinkDomain: "localhost"
      }

      this.$store.dispatch(LOGOUT)

      var email = this.form.email

      firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.signup = 2
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorCode, errorMessage)
        this.signup_errortxt = errorMessage
        this.signup = 9
      });
    },
    cancelSignup() {
      this.showForm('signin')
    },
    cancelForgot() {
      this.showForm('signin')
    },
    registerFacebook() {
      this.$router.push('/register?auth=facebook')
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    registerGoogle() {
      this.$router.push('/register?auth=google')
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    async registerCallback(result) {
      if (result.credential) {
        try {
          var result2 = await firebase.auth().currentUser.getIdToken(true)

          // Register with accessToken
          var options = {
            token: result2
          }

          this.axios.post('/register/token', options)
            .then(this.registerComplete)
            .catch(err => {
              // var data = err.response.data
              // if (data != null && data.code != null) {
              //   switch (data.code) {
              //     case 'auth/email-already-exists':
              //       this.error2.message = 'The email address is already in use by another account'
              //       break
              //   }
              // }
            })
            .finally(() => {
              // this.registering = false
            })
        } catch (err) {
        }
      }
    },
    registerComplete (result) {
      // this.complete = true
      // console.log('signup result', result)
      JwtService.saveToken(result.data.token)
      this.$router.push('/dashboard')
    },
    registerError (err) {
      console.log('signup error', err)
      var message = err.message || ''
      switch (err.code) {
        case 'auth/account-exists-with-different-credential':
          message = 'Account นี้ได้สมัครด้วย Social Network อื่นหรือ E-Mail/Password ปกติ ลอง Login ด้วยทางนั้นอีกครั้ง'
          break
      }

      this.signup = 9
      this.signup_errortxt = message
      // this.error.show = true
      // this.error.message = err.message
    },
    loginGoogle() {
      this.$router.push('/login?auth=google')
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    loginFacebook() {
      this.$router.push('/login?auth=facebook')
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    retryRegister() {
      this.state = 'signup'
      this.signup = 1
    }
  }
};
</script>
